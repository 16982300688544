import React from "react";
import { FooterNav } from "../components/FooterNav";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/Seo";

import { Button } from "../components/Button";

const NotFoundPage = (props) => {
  return (
    <Layout location={props.location} locale="pt">
      <Seo className="scroll license-bg" title="Page not found" />
      <div style={{ position: "relative" }}>
        <div
          className="container full color-white license-page"
          style={{ display: "block" }}
        >
          <h1 className="color-white">Page not found</h1>
          <p>Sorry, we can’t find the page you were looking for.</p>
          <br />
          <br />
          <Link to="/">
            <Button className={"yellow"} text="Back to Home page" />
          </Link>
        </div>
      </div>
      <FooterNav location={props.location} dark />
    </Layout>
  );
};

export default NotFoundPage;
